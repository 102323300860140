@charset "utf-8";
@use "sass:color";
// Our variables

$base-font-family: -apple-system, BlinkMacSystemFont, "Bitstream Vera Sans", Verdana, sans-serif;
$code-font-family: Menlo, Monaco, monospace;
$base-font-size:   14px;
$post-title-size:  26px;
$small-font-size:  12px;
$base-line-height: 1.5;

$spacing-unit:     30px;

$brand-color: #45418b;
$dark-brand-color: #25cae3;
$ends-background-color: aliceblue;
$ends-dark-background-color: #08353c;

$grey-color:       #828282;
// $grey-color-light: lighten($grey-color, 40%);
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
// $grey-color-dark:  darken($grey-color, 25%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

$on-palm:          800px;
$on-laptop:        1000px;

// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
