body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;

}

body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    background-color: $ends-background-color;

    @media (prefers-color-scheme: dark) {
        background: $ends-dark-background-color;
    }
}

strong {
    font-weight: 600;
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: calc($spacing-unit / 2);
}


/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}

img.about {
    width: 540px;

    @include media-query($on-palm) {
        width: 100%;
    }
}

img.booknotes, img.albumcover {
    @extend %clearfix;
    margin: 20px;
    max-width: 300px;
    float: right;

    @include media-query($on-palm) {
        max-width: 150px;
    }
}


/**
 * Figures
 */

figure.photography {
    display: block;
    width: 130%;
    margin-left: -15%;
    padding-top: calc($spacing-unit / 2);

    img {
        box-shadow: 0px 2px 8px rgba(0,0,0,0.33);
    }

    @include media-query($on-palm) {
        width: 100%;
        margin-left: auto;
        height: auto;
    }

    @include media-query($on-laptop) {
        width: 100%;
        margin-left: auto;
        height: auto;
    }
}

figure.wide {
    display: block;
    width: 130%;
    margin-left: -15%;
    padding-top: calc($spacing-unit / 2);

    @include media-query($on-palm) {
        width: 100%;
        margin-left: auto;
        height: auto;
    }

    @include media-query($on-laptop) {
        width: 100%;
        margin-left: auto;
        height: auto;
    }
}

.img-container {
    width: 100%;
    margin-left: auto;
    height: auto;

    text-align: center;
    padding-top: calc($spacing-unit / 2);
    padding-bottom: calc($spacing-unit / 2);

    img {
        display:inline-block;
    }
}

figure {
	display: table;
}

figcaption {
    caption-side: bottom;
    font-size: $small-font-size;
    font-weight: 300;
    font-style: italic;
    margin-top: 10px;
    text-align: right;
}

kbd {
  border-radius: 2px;
  padding: 2px;
  border: 1px solid gray;
  background-color: $grey-color-light;
  font-size: $base-font-size - 2;
}


/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
    list-style-position: outside;
}


/**
 * Headings
 */
h2, h3, h4 {
    font-weight: 600;
}

/**
 * Links
 */
a {
    color: $brand-color;
    text-underline-offset: 2px;

    &:visited {
        // leave color as it is
    }

    &:hover {
        // leave color as it is
    }

    @media (prefers-color-scheme: dark) {
        color: $dark-brand-color;
    }
}


/**
 * Blockquotes
 */
blockquote {
    border-left: 1px solid $brand-color;

    @media (prefers-color-scheme: dark) {
        border-left: 1px solid $dark-brand-color;
    }

    & {
        padding: calc($spacing-unit/4) calc($spacing-unit/2);
        margin-top: calc($spacing-unit * 1.10);
        margin-left: calc($spacing-unit/4);
    }

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */

pre,
code {
  font-family: $code-font-family;
  font-size: 0.9375em;
  border-radius: 3px;
  background-color: $grey-color-light;

  @media (prefers-color-scheme: dark) {
      color: white;
      background-color: #1d1d1d;
  }
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.highlight {
  border-radius: 3px;
  background: $grey-color-light;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: $grey-color-light;
  }

  @media (prefers-color-scheme: dark) {
      background: #1d1d1d;

      .highlighter-rouge & {
        color: white;
        background: #1d1d1d;
      }
  }
}


/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(800px - (#{$spacing-unit} * 2));
    max-width:         calc(800px - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(800px - (#{$spacing-unit}));
        max-width:         calc(800px - (#{$spacing-unit}));
        padding-right: calc($spacing-unit / 2);
        padding-left: calc($spacing-unit / 2);
    }
}

/**
 * Clearfix
 */
%clearfix {
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
}
