@use "sass:color";
/**
 * Site header
 */

.site-logo {
    height: 70px;
    padding-top: calc($spacing-unit / 2);
    padding-bottom: calc($spacing-unit / 2);
    filter: invert(23%) sepia(11%) saturate(6705%) hue-rotate(219deg) brightness(94%) contrast(83%);

    @media (prefers-color-scheme: dark) {
        filter: invert(74%) sepia(64%) saturate(1584%) hue-rotate(147deg) brightness(92%) contrast(93%);
    }
    @include media-query($on-palm) {
        max-height: 50px;
    }
}

.site-header {
    border-bottom: 1px solid $brand-color;
    position: relative;
    min-height: calc($spacing-unit * 2);
    background: aliceblue;

    @media (prefers-color-scheme: dark) {
        background: $ends-dark-background-color;
        border-bottom: 1px solid $dark-brand-color;
    }
}

.header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-query($on-palm) {
        display: block;
        text-align: center;
    }
}

.site-nav {
    float: right;
    line-height: 2em;

    .page-link {
        padding-top: calc($spacing-unit / 2);
        text-underline-offset: 3px;
        text-transform: capitalize;
        font-weight: 500;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        float: none;
        font-size: $base-font-size;
        padding-bottom: calc($spacing-unit / 2);
    }
}


/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $brand-color;
    padding: calc($spacing-unit) 0;
    background-color: $ends-background-color;

    @media (prefers-color-scheme: dark) {
        color: #d1d1d0;
        border-top: 1px solid $dark-brand-color;
        background: $ends-dark-background-color;
    }
}

.footer-col-wrapper {
    @extend %clearfix;
}


div.arabic {
    direction:rtl;
    text-align:justify;
}

div.latex {
    pre {
        white-space: pre-wrap;
        overflow-x: hidden;
    }
}

div.footnotes {
    font-size: 14px;
    padding-top: $spacing-unit;
    // border-top: 1px solid darken($grey-color, 25%);
    border-top: 1px solid color.adjust($grey-color, $lightness: -25%);
}

table, th, td {
    border: 1px solid #ddd;
}

th, td {
    padding: 5px;
    font-size: $base-font-size - 2;
    text-align: left;
    vertical-align: top;
}

tr:hover {
    background-color: #f5f5f5;
}

table {
    margin-bottom: calc($spacing-unit/2);
    border-spacing: 10px 5px;
    border-collapse: collapse;
}

div.update {
    background-color: rgba(0, 255, 0, 0.18);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    border: 1px solid #384338;
    margin-bottom: calc($spacing-unit/2);

    pre {
        background-color: #eef;
    }
}


div.preamble {
    background-color: aliceblue;
    border: 1px solid $brand-color;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 5px;

    margin-bottom: calc($spacing-unit/2);

    @media (prefers-color-scheme: dark) {
        background: $ends-dark-background-color;
        border: 1px solid $dark-brand-color;
    }
}

div.sidebar {
    border: 1px dotted;
    font-size: $base-font-size - 1;
    margin: calc($spacing-unit/2) auto;
    padding: 20px;
    width: 75%;

    p {
        margin: 0px;
    }

    h4 {
        text-align: center;
    }
}

div.hiddenseparator {
    hr {
        display: none;
    }
}

hr {
    color: none;
    background-color: none;
    border-top: 1px solid $brand-color;
    border-bottom: none;
    border-left: none;
    border-right: none;
    width: 60%;
    margin: 0 auto;
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;
}

/**
 * Page content
 */
.page-content {
    padding: calc($spacing-unit) 0;
    background: white;

    @media (prefers-color-scheme: dark) {
        background: #101010;
        color: #d1d1d0;
    }
}

.page-heading {
    font-size: 20px;
}

div.home-post-content {
    max-width: -webkit-calc(800px - (#{$spacing-unit} * 2));
    max-width:         calc(800px - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(800px - (#{$spacing-unit}));
        max-width:         calc(800px - (#{$spacing-unit}));
    }
}

.post-separator {
    width: 100%;
    text-align: center;
    clear: both;
    padding-top: $spacing-unit * 3;
    padding-bottom: $spacing-unit * 3;

    img {
        width: 50%;

        @include media-query($on-palm) {
            width: 100%;
        }
    }
}

.post-meta {
    font-size: $small-font-size;
    display: inline-block;
    margin-left: 0px;
    padding-bottom: calc($spacing-unit / 4);
    text-align: left;
    margin-bottom: 0px;
    padding-right: 10px;
}

/**
 * Posts
 */

.post-link, .post-title {
    font-size: $post-title-size;
    font-weight: 500;
    line-height: 1.3;
    a {
        text-underline-offset: 6px;
    }

    @include media-query($on-palm) {
        font-size: $post-title-size - 4;
    }
}

.albumnotetag {
    font-size: $small-font-size - 2;
    font-weight: 500;
    background: #ffe976;
    margin-left: calc($spacing-unit / 2);
    padding: 5px;
    border-radius: 3px;
    @media (prefers-color-scheme: dark) {
        color: black;
    }
}

/* book page tags */
.progressbar {
    font-family: monospace;
    font-size: $small-font-size;
    letter-spacing: 1px;
    color: $grey-color;
}

.booktag {
    border-radius: 2px;
    font-size: $small-font-size - 4;
    font-weight: 500;
    margin-left: 2px;
    padding: 2px;
    text-transform: uppercase;
    white-space: nowrap;
}

.abandonedtag {
    @extend .booktag;
    color: #ff4c4c;
    border: 1px dotted #ff4c4c;
}

.rereadtag {
    @extend .booktag;
    color: teal;
    border: 1px dotted teal;
}

.audiobooktag {
    @extend .booktag;
    color: #bb00bb;
    border: 1px dotted #bb00bb;
}

.booknotetag {
    @extend .booktag;
    color: black;
    border: 1px solid black;
    a {
      color: black;
    }

    @media (prefers-color-scheme: dark) {
        color: white;
        border: 1px solid white;

        a {
          color: white;
        }
    }
}

.briefnote {
    display: none;
    font-size: $small-font-size;
    margin-top: 5px;
    span {
        display: inline-block;
        padding: 3px;
        border: 1px dotted black;
        border-radius: 2px;
        background: #FCF3A7;

        @media (prefers-color-scheme: dark) {
            color: #FCF3A7;
            background: #101010;
            border: 1px dotted #FCF3A7;
            a {
              color: #FCF3A7;
            }
        }
    }

}

.briefnotelabel {
    border-radius: 2px;
    border: 1px dotted black;
    font-size: $small-font-size;
    padding: 3px;
    @media (prefers-color-scheme: dark) {
        color: white;
        border: 1px dotted white;
    }
}

.briefnotelabelactive {
    color: black;
    background: #FCF3A7;
    @media (prefers-color-scheme: dark) {
        color: #FCF3A7;
        background: #101010;
    }
}

.post-content {
    margin-bottom: $spacing-unit;
}

/**
 * Archive
 */

.archive-posts {
    display: table;
    table-layout: fixed;

    h3 {
        padding-top: calc($spacing-unit/2);
    }

    .archive-date {
        display: table-cell;
        padding-right: 14px;
        padding-top: 2px;
        font-size: $small-font-size;
        vertical-align: top;
        white-space: nowrap;
        width: 40px;

        @include media-query($on-palm) {
            font-size: $small-font-size - 2;
        }
    }

    .archive-post-name {
        display: table-cell;
        vertical-align: top;
    }
}

/**
 * Projects
 */

.project-tab-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: auto;
}

.project-tab {
    margin-bottom: 2%;
    width: 49%;
    padding: 2%;
    margin: 2px;
    box-sizing: border-box;
}

.project-image-wrapper {
    text-align: center;
}

.project-image {
    object-fit: cover;
    object-position: left;
    height: 200px;
    padding-bottom: calc($spacing-unit / 4);
}

.albumcontainer {
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    row-gap: 50px;
    column-gap: 1rem;

    hr {
      display: none
    }

    @include media-query($on-palm) {
        grid-template-columns: 100%;

        .item {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        hr {
          width: 95%;
          display: block;
        }

        img.albumcover {
          width: 60%;
          max-width: 60%;
          margin: 0;
        }
    }

    .albumpermalink {
        float: right;
    }

    .albumplayed {
        padding: 1px;
        background:  lightgoldenrodyellow;

        @media (prefers-color-scheme: dark) {
            background: #08353c;
            color: #d1d1d0;
        }
    }
}
